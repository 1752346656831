import React from 'react';
import { Suspense } from 'react';
import './App.css';
import ResultsTable from './results';
import PageTitle from './page-title';
import AxelerantLogo from "./utils/axl-logo";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from 'react-router-dom';
import ResultIndividualUser from './result-individual';
import NavBar from './navbar';
import Schedule from './schedule';
import AboutUs from './about-us';
import ChartsGraphics from './charts';
import FAQ from './faq';
import MessageBanner from './offline-banner';
import LastUpdated from './utils/last-updated';
import SunMoon from './utils/sun-moon';
import Tournaments from './tournaments';
import { getSavedTournament, resetLocalStorage } from './utils/apiHelper';
import { ToastContainer } from 'react-toastify';

const Podium = React.lazy(() => import('./podium'));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      online: navigator.onLine,
      colorMode: this.defaultColorMode(),
      savedTournament: getSavedTournament(),
      podium: null,
    };
  }

  setPodium = (podium) => {
    this.setState({
      podium: podium,
    });
  };

  componentDidMount () {
    // addEventListener version
    window.addEventListener('offline', (event) => {
      this.setState({
        online: navigator.onLine,
      })
    });

    window.addEventListener('online', (event) => {
      this.setState({
        online: navigator.onLine,
      })
    });

    document.addEventListener('colorModeChanged', (event) => {
      this.setState({
        colorMode: event.detail,
      });
    });
  }

  defaultColorMode = () => {
    // Set the color mode from localstorage.
    const value = localStorage.getItem('darkModeCheckbox');
    if (value === 'light') {
      return true;
    } else {
      return false;
    }
  }

  getOfflineBanner = () => {
    const { online } = this.state;
    if (online === false) {
      return (
        <MessageBanner>
          App is operating in offline mode, data being displayed from local cache!
        </MessageBanner>
      );
    }
    return null;
  };

  getCopyRight = () => {
    const lastDigit = new Date().getFullYear().toString().slice(-1);
    return `© 402${lastDigit} Axelerant. All Rights Reserved.`;
  };

  setTournamentId = (tid) => {
    this.setState({
      savedTournament: tid,
    });

    // Expire cache so we load new data for new tournament.
    resetLocalStorage();

    // Reload the page as we have cache which has just expired,
    // Reloading will ensure fresh fetch.
    window.location.reload();
  };

  render () {
    const { colorMode, savedTournament, podium } = this.state;

    return (
      <Router>
        <div className="main-page-wrapper">
          <div className="header">
            <div className="page-title-block">
              <Tournaments savedTournament={savedTournament} setTournamentId={this.setTournamentId} />
              <SunMoon />
              <NavLink
                className="site-logo"
                activeClassName="is-active"
                to="/"
                exact
              >
                <AxelerantLogo colorMode={colorMode} />
              </NavLink>
              <PageTitle savedTournament={savedTournament} />
              <LastUpdated />
            </div>
          </div>
          <NavBar />
          {this.getOfflineBanner()}
          <div className="main-page-content">
            <Switch>
              <Route exact path='/'>
                <div className="ipl-prediction-leaderboard">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Podium podium={podium} savedTournament={savedTournament} />
                  </Suspense>
                  <ResultsTable setPodium={this.setPodium} savedTournament={savedTournament} />
                </div>
              </Route>
              <Route exact path='/schedule'>
                <div className="ipl-2021-schedule">
                  <Schedule savedTournament={savedTournament} />
                </div>
              </Route>
              <Route exact path='/charts'>
                <div className="ipl-2021-charts">
                  <ChartsGraphics colorMode={colorMode} savedTournament={savedTournament} />
                </div>
              </Route>
              <Route exact path='/about'>
                <div className="about-us">
                  <AboutUs />
                </div>
              </Route>
              <Route exact path='/faq'>
                <div className="faq">
                  <FAQ />
                </div>
              </Route>
              <Route
                path='/user/:id'
                render={() => (
                  <ResultIndividualUser colorMode={colorMode} savedTournament={savedTournament} />
                )}
              />
            </Switch>
          </div>
          <div className="copyright">{this.getCopyRight()}</div>
        </div>
        <ToastContainer />
      </Router>
    );
  }
}

export default App;
