import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import ReactGA from 'react-ga4';

// // Google Analytics.
// ReactGA.initialize('G-WKDS0V1DHR'); // add your tracking id here.
// // ReactGA.pageview(window.location.pathname + window.location.search);
// ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

const container = document.getElementById('root');

// Create a root
const root = ReactDOM.createRoot(container);

// Start the root.
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

serviceWorkerRegistration.unregister();
