import React, { useEffect, useRef } from 'react';
import uPlot from 'uplot';
import './uPlot.min.css';

function MyPlot(props) {
  const plotRef = useRef();

  useEffect(() => {
    const { options, data } = props;
    let plotInstance;

    if (plotRef.current) {
      plotInstance = new uPlot(options, data, plotRef.current);
    }

    return () => {
      if (plotInstance) {
        plotInstance.destroy();
      }
    };
  }, [props]);

  return (
    <div ref={plotRef} />
  );
}

export default MyPlot;
