import { createClient } from '@supabase/supabase-js';
import sortIPLMatchesChronologically from './cricapiHelper';

// Read Supabase credentials from environment variables
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

// Check if the environment variables are set
if (!supabaseUrl) {
  console.error('Error: REACT_APP_SUPABASE_URL environment variable is not set.');
}
if (!supabaseAnonKey) {
  console.error('Error: REACT_APP_SUPABASE_ANON_KEY environment variable is not set.');
}

// Create and export the Supabase client instance
// Handle the case where variables might be missing to avoid runtime errors
export const supabase = (supabaseUrl && supabaseAnonKey)
  ? createClient(supabaseUrl, supabaseAnonKey)
  : null;

/**
 * Inserts data into the 'match-results' table.
 * NOTE: This is a one time call function used to feed data into the table.
 *
 * @param {object} apiData - The parsed JSON data from the CricAPI endpoint.
 */
export const insertMatchResults = async (apiData) => {
  if (!supabase) {
    console.error('Supabase client is not initialized. Check environment variables.');
    return { error: 'Supabase client not initialized' };
  }

  if (!apiData || !apiData.data || !apiData.data.matchList) {
     console.error('Invalid or empty data received from API.');
     return { error: 'Invalid API data structure' };
  }

  console.log('Attempting to insert data into Supabase table: match-results');
  console.log('Received data:', apiData);
  // Sorting the match data.
  const sortedMatchList = sortIPLMatchesChronologically(apiData.data);
  console.log('Sorted Match data:', sortedMatchList);

  const matchesToInsert = sortedMatchList.map((match, index) => { // Added index parameter
    return {
      axl_id: `ipl2025-${index + 1}`, // Use index + 1 for sequential IDs
      api_id: match.id,
      summary: match.status,
      name: match.name,
      matchEnded: match.matchEnded,
      matchStarted: match.matchStarted,
      team1: match.teams?.[0],
      team2: match.teams?.[1],
      api_date: match.date,
      api_date_time: match.dateTimeGMT,
    };
  });

  console.log('Mapped data to insert:', matchesToInsert);

  // Perform the insertion
  // Use { returning: 'minimal' } for efficiency if you don't need the inserted rows back
  // Use upsert if you need to handle potential duplicate entries based on a unique constraint (e.g., match_id)
  const { data, error } = await supabase
    .from('match-results')
    .upsert(matchesToInsert, {onConflict: 'api_id'});
  // Handle the response
  if (error) {
    console.error('Error inserting data into Supabase:', error);
    return { error };
  }

  console.log('Data successfully inserted/upserted into Supabase:', data);
  return { data };
};

/**
 * Fetches a single match result from the 'match_results' table based on axl_id
 * if matchEnded is true.
 *
 * @param {string} axlId - The unique AXL ID for the match.
 * @returns {Promise<{ data: object | null, error: object | null }>} - The fetched match data or an error object.
 */
export const getMatchResultByAxlId = async (axlId) => {
  if (!supabase) {
    console.error('Supabase client is not initialized. Check environment variables.');
    return { data: null, error: 'Supabase client not initialized' };
  }

  if (!axlId) {
    console.error('axlId parameter is required.');
    return { data: null, error: 'axlId parameter is required' };
  }

  // console.log(`Fetching match result for axl_id: ${axlId}`);

  const { data, error } = await supabase
    .from('match_results') // Using 'match_results' as requested
    .select('*')
    .eq('axl_id', axlId)
    .eq('matchEnded', true) // Ensure we only fetch ended matches
    .single(); // Use single() as axl_id is expected to be unique

  if (error) {
    console.error(`Error fetching match result for axl_id ${axlId}:`, error);
    return { data: null, error };
  }

  // console.log(`Successfully fetched match result for axl_id ${axlId}:`, data);
  return { data, error: null };
};
